
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

const TermsAndConditions = () => {
  return (
    <div className="app">
      <Header />

      <div className="grow px-8 md:px-20 3xl:px-40 text-justify leading-5">
        <h2 className='text-green text-2xl md:text-3xl xl: font-bold text-center mb-6'>
          Termos e Condições do Budd Usuário
        </h2>

        <p className='mt-2 text-lg md:text-xl font-medium'>
          Bem-vindo ao <span className='text-green'>Budd</span>! Estes Termos e Condições regulam o uso do aplicativo Budd Usuário, onde você pode explorar bares e eventos, visualizar informações e em breve, realizar compras de ingressos e produtos.
        </p>

        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>1. Nossos Serviços</h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>
          O Budd é um aplicativo projetado para tornar sua experiência de entretenimento mais completa e satisfatória. Através do Budd, você pode:
        </p>
        <ul className='list-disc ml-8 mt-2 text-lg md:text-xl font-medium'>
          <li><strong>Explorar Locais:</strong> Descubra os melhores bares e eventos próximos a você.</li>
          <li><strong>Bares e Eventos:</strong> Fique por dentro dos bares e eventos que acontecem na sua cidade ou região.</li>
          <li><strong>Localização e Navegação:</strong> Utilize nossa tecnologia de localização para encontrar os melhores locais noturnos.</li>
        </ul>

        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>2. Perfil do Usuário</h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>
          Ao criar seu perfil de usuário, você pode manter um registro de seus bares preferidos e eventos que frequentou. Seu perfil permite que você compartilhe suas experiências e avalie estabelecimentos.
        </p>

        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>3. Compras e Transações</h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>
          Em breve, você poderá comprar ingressos e produtos diretamente pelo aplicativo Budd. Todas as transações serão realizadas de forma segura, e você será informado sobre as políticas de reembolso dos estabelecimentos parceiros.
        </p>

        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>4. Privacidade e Segurança de Dados</h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>
          A sua privacidade é importante para nós. Todos os dados coletados são tratados de acordo com nossa Política de Privacidade e em conformidade com as exigências do Google Play.
        </p>

        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>5. Modificações dos Termos e Condições</h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>
          O Budd reserva-se o direito de modificar estes Termos e Condições a qualquer momento. Quaisquer alterações serão publicadas no aplicativo.
        </p>

        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>6. Contato</h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>
          Se você tiver qualquer dúvida sobre estes Termos e Condições, entre em contato conosco: <br />
          E-mail: <span className='text-green'>ricardosilvanet03@gmail.com</span><br />
          Telefone/WhatsApp: <span className='text-green'>+55 (98) 98404-7409</span>
        </p>

        <h2 className='text-green text-2xl md:text-3xl xl: font-bold text-center mb-6 mt-10'>
          Política de Privacidade do Budd Usuário
        </h2>

        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>1. Introdução</h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>
          Esta Política de Privacidade descreve como o Budd coleta, utiliza e protege os dados pessoais dos usuários.
        </p>

        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>2. Coleta de Dados</h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>
          O Budd coleta informações como nome, endereço de e-mail, número de telefone e localização para fornecer um serviço personalizado.
        </p>

        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>3. Uso dos Dados</h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>
          Os dados coletados são usados para fornecer e melhorar os serviços do Budd, comunicação e segurança.
        </p>

        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>4. Compartilhamento de Dados</h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>
          O Budd não compartilha suas informações pessoais com terceiros sem o seu consentimento, exceto em serviços como AWS Cognito e Stripe.
        </p>

        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>5. Segurança dos Dados</h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>
          Utilizamos medidas de segurança para proteger suas informações contra acesso não autorizado.
        </p>

        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>6. Direitos do Usuário</h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>
          Você tem o direito de acessar, corrigir e solicitar a exclusão dos seus dados.
        </p>

        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>7. Atualizações da Política de Privacidade</h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>
          Podemos atualizar esta Política de Privacidade periodicamente.
        </p>

        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>8. Contato</h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>
          Para dúvidas sobre nossa Política de Privacidade, entre em contato: <br />
          E-mail: <span className='text-green'>ricardosilvanet03@gmail.com</span><br />
          Telefone/WhatsApp: <span className='text-green'>+55 (98) 98404-7409</span>
        </p>
      </div>

      <Footer />
    </div>
  );
};

export default TermsAndConditions;