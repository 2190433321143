import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

const OurServices = () => {
  return (
    <div className="app">
      
      <Header />
      
      <div className="grow px-8 md:px-20 3xl:px-40 text-justify leading-5">

        <h2 className='text-green text-2xl md:text-3xl xl: font-bold text-center mb-6'>Nossos serviços</h2>
        
        <p className='mt-2 text-lg md:text-xl font-medium'>No <span className='text-green'>budd</span> brasil, oferecemos uma variedade de 
        serviços projetados para tornar sua vida mais fácil e sua experiência conosco 
        mais gratificante. Descubra como podemos ajudar você a aproveitar ao máximo 
        o nosso aplicativo.</p>

        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>Exploração de Locais</h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>Se você está procurando os melhores bares, shows, eventos 
        ou qualquer outro tipo de estabelecimento, o <span className='text-green'>budd</span> está aqui para ajudar. 
        Nossa plataforma permite que você explore locais próximos, 
        e encontre as opções que atendem às suas preferências.</p>

        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>Bares e Eventos</h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>Mantenha-se atualizado sobre os bares e eventos que estão
        acontecendo em sua cidade ou região. Com o <span className='text-green'>budd</span>, você pode encontrar informações 
        sobre shows, exposições, 
        festivais e muito mais. Nunca perca uma oportunidade emocionante novamente.</p>

        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>Localização e Navegação</h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>Nossa tecnologia de localização avançada permite que 
        você navegue facilmente pela sua cidade. Encontre bares eventos, evite filas e 
        descubra os melhores bares e shows noturnos.</p>

        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>Perfil do Usuário</h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>Crie seu perfil de usuário e mantenha um registro de 
        suas compras e locais frequentados. Compartilhe conosco suas experiências 
        e ajude outros usuários a encontrar os melhores lugares e eventos.</p>

        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>Suporte ao Cliente</h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>Estamos aqui para você. 
        Se você tiver alguma dúvida, problema técnico ou apenas 
        desejar dar um feedback, nossa equipe de suporte ao 
        cliente está pronta para ajudar. 
        Entre em contato conosco a qualquer momento.</p>
        
        <h3 className='text-green text-2xl md:text-3xl xl: font-bold mb-5 mt-8'>Contato</h3>
        <p className='mt-2 text-lg md:text-xl font-medium'>Em caso de dúvidas ou preocupações ,por favor entre 
        em contato conosco através do e-mail: <span className='text-green'>ricardosilvanet03@gmail.com</span></p>
      </div>

      <Footer />

    </div>
  );
};

export default OurServices;