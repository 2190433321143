import { useEffect } from 'react';
import { Link, NavLink } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { useRef, useState } from "react";

export default function Header() {
    
    const navRef = useRef<HTMLDivElement | null>(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        if (navRef.current) {
            navRef.current.classList.toggle("responsive_nav");
            setIsMenuOpen(!isMenuOpen);
        }
    }

    useEffect(() => {
        if (isMenuOpen) {
            document.body.classList.add('overflow-hidden');
        } else {
            document.body.classList.remove('overflow-hidden');
        }
    }, [isMenuOpen]);

    return (
        <header className="font-ubuntu bg-black flex justify-between items-center text-2xl top-0 z-50 px-8 md:px-20 3xl:px-40 h-20 mb-10">
            
            <h1 className="text-2xl lg:text-3xl font-bold text-green">
                <Link to="/">budd</Link>
            </h1>
            
            <nav ref={navRef} className={`bg-black text-lg lg:text-xl font-medium gap-6 fixed xl:static top-0 left-0 h-screen xl:h-auto w-screen flex flex-col xl:flex-row items-center justify-center xl:justify-end transition transform -translate-y-full xl:transform-none ${isMenuOpen ? "transform-none" : ""}`}>
                <NavLink to="/" className="text-white my-0 mx-4 relative">Home</NavLink>
                <NavLink to="/termsandconditions" className="text-white my-0 mx-4 relative">Termos e condições</NavLink>
                <NavLink to="/weare" className="text-white my-0 mx-4 relative">Quem somos</NavLink>
                <NavLink to="/ourservices" className="text-white my-0 mx-4 relative">Nossos serviços</NavLink>
                {/* <NavLink to="/" className="text-white my-0 mx-4 relative">Analytics</NavLink> */}
                <a href="https://api.whatsapp.com/send?phone=559892175906" target="_blank" rel="noreferrer" className="text-black py-2 px-4 bg-green rounded-lg ">Login</a>
                <a href="https://api.whatsapp.com/send?phone=559892175906" target="_blank" rel="noreferrer" className="text-black py-2 px-4 bg-green rounded-lg ">WhatsApp IA</a>
                

                
                <button className="block absolute top-6 right-8 ---------- cursor-pointer bg-transparent text-green border-none outline-none text-2xl xl:hidden" onClick={toggleMenu}>
                    <FaTimes/>
                </button>
            </nav>

            <button className="block cursor-pointer bg-transparent text-green border-none outline-none text-2xl xl:hidden" onClick={toggleMenu}>
                <FaBars />
            </button>

        </header>
    )
}
