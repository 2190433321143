import gitHubSvg from "../../assets/github.svg";
import linkedinSvg from "../../assets/linkedin.svg";

interface ProfileCardProps {
    photo: string;
    name: string;
    charge: string;
    description: string;
    gitHub: string;
    linkedin: string;
}

export default function ProfileCard ( {photo, name, charge, description, gitHub, linkedin} : ProfileCardProps ) {
    return (
        <div className="bg-black w-48 rounded-2xl py-7 px-4 flex flex-col justify-center items-center shadow-2xl">
            <img src={photo} alt={name} className="w-24 border-solid border-2 border-white rounded-full"/>
            <p className="text-lg mt-2">{name}</p>
            <p className="text-xs font-light mb-2">{charge}</p>
            <span className="bg-white h-px w-full my-1"></span>
            <p className="mt-2 mb-4 text-sm font-light leading-5 text-center">{description}</p>
            <div className="flex gap-4 justify-center items-center">
                <a href={gitHub} target="_Blank" rel="noreferrer">
                    <img src={gitHubSvg} alt="github" className="w-8"/>
                </a>
                <a href={linkedin} target="_Blank" rel="noreferrer">
                    <img src={linkedinSvg} alt="github" className="w-7"/>
                </a>
            </div>
        </div>
    )
}