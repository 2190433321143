import ReactDOM from 'react-dom/client';
import './styles/global.css';
import { BrowserRouter } from "react-router-dom"
import MainRoutes from './routes';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <BrowserRouter>
    <MainRoutes />
  </BrowserRouter>
);