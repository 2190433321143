import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import tela1 from '../../assets/tela1.png';
import tela2 from '../../assets/tela2.png';
import tela3 from '../../assets/tela3.png';
import tela4 from '../../assets/tela4.png';
import './styles.css'

const settings = {
    dots: false,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000
  };

export default function SliderApp () {
    return (
        <div className='slider'>
          <Slider {...settings} className="meuCarrossel">
            <div><img src={tela1} alt="Tela 1"/></div>
            <div><img src={tela2} alt="Tela 2" /></div>
            <div><img src={tela3} alt="Tela 3" /></div>
            <div><img src={tela4} alt="Tela 4" /></div>
          </Slider>
        </div>
    )
}