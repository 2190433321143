import {Routes, Route} from "react-router-dom"
import Home from "./pages/Home/Home"
import Weare from "./pages/WeAre/WeAre"
import OurServices from "./pages/OurServices/OurServices"
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions"

function MainRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/weare" element={<Weare/>} />
            <Route path="/ourservices" element={<OurServices/>} />
            <Route path="/termsandconditions" element={<TermsAndConditions/>} />
        </Routes>
    )
}

export default MainRoutes