import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import SliderApp from '../../components/Slider/Slider';

function Home() {
  return (
      <div className="app"> {/* Fundo preto para todo o App */}

        <Header />

        <div className="w-full px-8 md:px-20 3xl:px-40 flex flex-col lg:flex-row justify-between items-center">
          <div className="w-full lg:w-2/4">
            <h1 className='font-ubuntu font-bold text-3xl sm:text-4xl lg:text-5xl 2xl:text-6xl text-green'>
              Desfrute dos melhores eventos e bares próximos de você, com apenas alguns cliques!
            </h1>
            <p className='font-sans mt-8 text-lg sm:text-xl lg:text-2xl'>
              Descubra bares, eventos, realize seus pedidos antecipadamente e aproveite a vida noturna de maneira conveniente e segura, tudo direto do seu smartphone.
            </p>
          </div>
          <div className="w-full lg:w-2/4 mt-20 lg:mt-0">
            <SliderApp />
          </div>
        </div>
 
        <Footer />

      </div>
  );
}

export default Home;
